.top-bar {
  .toggle-btn {
    font-size: calc(var(--bs-body-font-size) + 10px);
    color: #fff;
    @media (max-width: 1199.98px) {
      padding: 0;
    }
  }
  &.sb-topnav {
    z-index: 1036;
    padding: 0 24px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 56px;
    @media (min-width: 576px) {
      height: 72px;
      padding: 0 24px;
    }
    @media (min-width: 1200px) {
      padding: 0 38px;
    }
    @media (min-width: 1537px) {
      padding: 0 52px;
    }
    .navbar-brand {
      width: 225px;
      margin: 0;
    }
    .nav-item {
      margin: 0 10px;
      @media (max-width: 1199.98px) {
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &.navbar-dark .navbar-light .navbar-nav .nav-link {
      color: #fff;
    }
    &.navbar-light .navbar-nav .nav-link {
      color: #fff;
    }
  }
  .custom-top-header-alert {
    font-size: var(--bs-body-font-size);
    font-weight: bold;
    letter-spacing: 0.16px;
    top: 12px;
    width: max-content;
    z-index: 1;
    line-height: 20px;
    box-shadow: 0 0.5rem 0.3rem rgba(0, 0, 0, 40%);
    @media (max-width: 1080px) {
      bottom: 12px;
      top: auto;
    }
    @media (max-width: 420px) {
      width: auto;
    }
  }

  @keyframes fadeinout {
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  .nav-link {
    font-size: var(--bs-body-font-size);
    justify-content: center;
    display: flex;
    .top-2 {
      top: 2px;
    }
    @media (max-width: 1199.98px) {
      padding-right: 0 !important;
    }
    @media (max-width: 575.98px) {
      padding: 16px 0px 16px 16px !important;
    }
    i {
      font-size: calc(var(--bs-body-font-size) + 10px);
      @media (max-width: 575.98px) {
        font-size: calc(var(--bs-body-font-size) + 8px);
        line-height: 24px;
      }
    }
  }
  .d-touch {
    display: block;
    @media (min-width: 1199.98px) {
      display: none;
    }
  }
  .img-section {
    .img-info {
      padding-right: 18px;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 1199.98px) {
        padding-right: 16px;
      }
      @media (max-width: 575.98px) {
        padding-right: 12px;
      }
      .welcome-info {
        font-size: var(--bs-body-font-size);
        letter-spacing: 0.13px;
        color: #ffffff;
      }
      .user-name {
        font-size: calc(var(--bs-body-font-size) + 2px);
        text-transform: capitalize;
        font-weight: var(--bs-body-font-weight-medium);
        letter-spacing: 0.16px;
        color: #ffffff;
      }
    }
    .user-img {
      height: 52px;
      width: 52px;
      border-radius: 50%;
      @media (max-width: 575.98px) {
        height: 36px;
        width: 36px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
      }
    }
    .dropdown-toggle::after {
      display: none;
    }
  }
}
