.logout-main {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(6, 30, 74, 0.8);
  z-index: 1;
}
.sb-nav-fixed1 {
  height: 100vh;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;

  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../../assets/img/logout-bg.png) 50% no-repeat;
  background-size: cover;
  position: relative;
}
.form-signin {
  max-width: 330px;
  padding: 1rem;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bgspecial {
  background-color: #f8f9fc !important;
}
