.custom-filter-select {
    height: 48px;
    .css-13cymwt-control {
        height: 48px;
        background: #f8f9fa;
        border: 1px solid #bcc9d9;
        border-radius: 8px;
        box-shadow: none !important;
        @media (min-width: 1199.98px) and (max-height: 749.98px) {
            height: 40px;
        }
        @media (max-width: 1199.98px) {
            height: 40px;
        }
    }
    .css-t3ipsp-control {
        border: none;
        background: #f0f7ff;
        box-shadow: 0 0 0 1px #014694 !important;
    }
    .css-hlgwow {
        height: 48px;
        padding-left: 16px;

        @media (min-width: 1199.98px) and (max-height: 749.98px) {
            height: 40px;
        }
        @media (max-width: 1199.98px) {
            height: 40px;
        }
    }
    .css-t3ipsp-control:hover {
        border: none;
        background: #f0f7ff;
        box-shadow: 0 0 0 1px #014694 !important;
    }
    .css-1u9des2-indicatorSeparator {
        display: none;
    }
    .css-1wy0on6 {
        padding-right: 8px;
    }
    .css-1xc3v61-indicatorContainer {
        color: #7d8998;
    }
    .css-1nmdiq5-menu {
        z-index: 2;
    }
}
.ins-switch {
    background: none !important;
    border-right: none !important;
    border-left: none !important;
    z-index: 9;
    position: relative;
    .form-switch {
        margin-bottom: 0px;
        .form-check-input {
            width: 56px !important;
            height: 28px !important;
            cursor: pointer;
            margin-top: 0px;
        }
    }
    .info-content {
        display: flex;
        gap: 16px;
        .h-26 {
            height: 26px;
        }
    }
    .dropdowninfo {
        font-size: 20px;
        color: #f04a02;
    }
}
