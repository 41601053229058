@import "./../../scss/breakpoints";

.user-info-page {
  .card {
    .card-top-header {
      font-size: calc(var(--bs-body-font-size) + 6px);
      font-weight: var(--bs-body-font-weight-medium);
      line-height: 30px;
      letter-spacing: 0.24px;
      color: #000000;
      margin-bottom: 24px;
    }
    .card-body {
      i {
        position: absolute;
        font-size: calc(var(--bs-body-font-size) + 8px);
      }

      .label-block {
        margin-left: 40px;

        .label-header {
          text-align: left;
          font-size: var(--bs-body-font-size);
          line-height: 20px;
          letter-spacing: 0.28px;
          color: #757575;
        }
        .label-info {
          text-align: left;
          font-size: calc(var(--bs-body-font-size) + 2px);
          line-height: 26px;
          letter-spacing: 0.32px;
          color: #212121;
        }
      }
    }
    &.cms-card {
      .mem-info {
        display: flex;
        flex-direction: column;
        padding-right: 40px;
        @media (min-width: 1399.99px) and (max-width: 1799.98px) {
          padding-right: 20px;
        }

        @include xxl {
          padding-right: 10px;
        }
        @include sm {
          padding-right: 0;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
        // @media (min-width: 1537px) {
        //     padding-right: 72px;
        // }

        .member-info {
          font-size: calc(var(--bs-body-font-size) + 8px);
          font-weight: bold;
          letter-spacing: 0.24px;
          line-height: 30px;
          margin-bottom: 8px;
          width: 100%;

          @include xxl {
            font-size: calc(var(--bs-body-font-size) + 6px);
            line-height: 28px;
          }
        }
        .member-name {
          text-align: left;
          font-size: var(--bs-body-font-size);
          font-weight: var(--bs-body-font-weight-medium);
          letter-spacing: 0.16px;
          margin-bottom: 28px;
          .mem-mini-info {
            font-size: var(--bs-body-font-size);
            line-height: 22px;
            letter-spacing: 0.16px;
          }
          @include sm {
            text-align: center;
          }
        }
      }
      .member-img-responsive {
        display: none;
        width: 100%;
        margin-bottom: 28px;
        @include sm {
          display: block;
        }
        img {
          width: 245px;
        }
      }

      .member-img {
        // width: 245px;
        // height: 245px;
        // width: 180px;
        // height: 180px;
        border-radius: 50%;
        // @media (min-width: 1367px) {
        //     width: 200px;
        //     height: 200px;
        // }
        // @media (min-width: 1537px) {
        //     width: 245px;
        //     height: 245px;
        // }
        img {
          width: 100%;
          height: 100%;
        }

        @include sm {
          display: none;
        }
      }

      .member-card-body {
        @media (min-width: 991.99px) and (max-width: 1199.98px) {
          .col-12.col-sm-5 {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
          }
          .member-img {
            width: 245px;
          }
        }
      }
    }
    .user-img {
      min-width: 96px;
      height: 96px;
      @media (min-width: 1537px) {
        min-width: 116px;
        height: 116px;
      }
      border-radius: 50%;
      // opacity: 0.2;
      //border: 7px solid;

      @include sm {
        min-width: 60px;
        height: 60px;
      }
      @include xs {
        min-width: 50px;
        height: 50px;
      }
      img {
        // width: 100%;
        height: 100%;
        // object-fit: cover;
        object-position: top center;
        border-radius: 50%;
        box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
      }

      + .info-block {
        margin-left: 24px !important;

        @media (min-width: 1537px) {
          margin-left: 32px;
        }
      }
    }
    &.info-card {
      color: #fff;
      text-transform: capitalize;
      .card-top-header {
        color: #fff;
      }
      .card-body {
        i {
          color: #fff;
          position: absolute;
          font-size: calc(var(--bs-body-font-size) + 8px);
        }

        .center-blocks {
          word-break: break-all;
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          @include xs {
            flex-direction: column;
            > .d-flex {
              margin: 0px 0 0 !important;
            }
          }
        }
        .info-block {
          margin-left: 40px;
          .member-header {
            font-size: calc(var(--bs-body-font-size) + 4px);
            line-height: 30px;
            font-weight: var(--bs-body-font-weight-medium);
            letter-spacing: 0.24px;
            color: #ffffff;
            margin-bottom: 8px;
            text-transform: capitalize;
            @media (min-width: 1537px) {
              font-size: calc(var(--bs-body-font-size) + 8px);
            }

            @include sm {
              font-size: calc(var(--bs-body-font-size) + 4px);
              line-height: 20px;
            }
            @include xs {
              font-size: calc(var(--bs-body-font-size) + 4px);
              margin-bottom: 4px;
            }
          }
          .gender-value {
            text-align: left;
            font-size: calc(var(--bs-body-font-size) + 2px);
            line-height: 26px;
            letter-spacing: 0.32px;
            color: #ffffff;
            margin-bottom: 8px;
            @include xxl {
              font-size: var(--bs-body-font-size);
              line-height: 18px;
              margin-bottom: 8px;
            }
            @include xs {
              margin-bottom: 4px;
            }
          }
          .dob {
            text-align: left;
            font-size: calc(var(--bs-body-font-size) + 2px);
            line-height: 26px;
            letter-spacing: 0.32px;
            color: #ffffff;
            @include xxl {
              font-size: var(--bs-body-font-size);
              line-height: 18px;
            }
          }
          .info-header {
            font-size: var(--bs-body-font-size);
            font-weight: var(--bs-body-font-weight-medium);
            line-height: 20px;
            letter-spacing: 0.26px;
          }
          .info-value {
            font-size: calc(var(--bs-body-font-size) + 2px);
            line-height: 26px;
            letter-spacing: 0.32px;
            @include xxl {
              font-size: var(--bs-body-font-size);
            }
          }
          .email {
            text-transform: none;
          }
        }
      }
      .address-bar {
        padding-top: 8px;
        word-break: break-word;
        i {
          padding-top: 2px;
        }
      }
    }
    .custom-header-responsive {
      @media (min-width: 420px) and (max-width: 575px) {
        flex-direction: row !important;
      }
    }
    .reported-info {
      font-size: var(--bs-body-font-size);
      margin-top: 8px;
      font-weight: var(--bs-body-font-weight-medium);
    }
    .cms-switch {
      // margin-top: 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      //flex-wrap: wrap;
      font-size: var(--bs-body-font-size);
      border-radius: 16px;
      padding: 16px 24px;
      line-height: 20px;
      text-transform: capitalize;
      letter-spacing: 0.32px;
      //max-width: 276px;
      min-height: 68px;
      // @media (min-width: 1537px) {
      //     height: 68px;
      // }

      .dropdowninfo {
        margin-left: 12px;
        display: flex;
      }
      .icons-info {
        display: flex;
        align-items: center;
      }
      .warn-icon {
        position: relative;
      }
      @media (min-width: 1199.99px) and (max-width: 1299.98px) {
        padding: 16px 12px;
        height: 56px;
      }
      @include sm {
        min-width: 220px;
        height: 56px;
        padding: 16px 20px;
      }
      .sb-nav-link-icon {
        font-size: calc(var(--bs-body-font-size) + 2px);
      }
      span {
        letter-spacing: 0.16px;
        font-size: var(--bs-body-font-size);
        font-weight: bold;
        padding-right: 16px;
        @media (min-width: 1199.99px) and (max-width: 1299.98px) {
          font-size: calc(var(--bs-body-font-size) - 2px);
        }
      }
      .form-switch {
        margin-bottom: 0px;
        .form-check-input {
          width: 48px !important;
          height: 24px !important;
          @media (min-width: 1537px) {
            height: 36px !important;
            width: 69px !important;
          }
          cursor: pointer;
          margin-top: 0px;
        }
      }
    }
  }
}

.about-me-popup {
  .modal-header {
    .modal-title {
      margin-bottom: 0;
    }
    .btn-close {
      display: none;
    }
  }
  .modal-body {
    text-align: left;
    font-size: var(--bs-body-font-size);
    letter-spacing: 0.16px;
  }
}
