.custom-loader-info {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1041;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    background: #e6ebf0;
    color: #000;
    .custom-loader {
        @keyframes rotating {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
        .rotating {
            animation: rotating 3s linear infinite;
            width: fit-content;
            i {
                font-size: 150px;
            }
        }
    }
    .loader-info {
        font-size: 60px;
        position: relative;
    }
}
