.changepassword-list {
  padding: 36px 72px;
  .outline-primary {
    background: #0146940d;
    border: 1px solid #014694b3;
    border-radius: 8px;
    height: 48px;
    display: flex;
    align-items: center;
    svg {
      color: #014694;
      margin-right: 10px;
      font-size: calc(var(--bs-body-font-size) + 2px);
    }

    span {
      text-align: left;
      font-size: var(--bs-body-font-size);
      line-height: 24px;
      font-weight: var(--bs-body-font-weight-medium);
      letter-spacing: 0px;
      color: #014694;
    }
    &:active {
      background: #0146940d;
    }
  }
  .fill-primary {
    background: #014694;
    border: 1px solid #7d8998;
    border-radius: 8px;
    height: 48px;
    display: flex;
    align-items: center;
    svg {
      color: #fff;
      margin-right: 10px;
      font-size: calc(var(--bs-body-font-size) + 2px);
    }
    span {
      text-align: left;
      font-size: calc(var(--bs-body-font-size) + 2px);
      font-weight: var(--bs-body-font-weight-medium);
      letter-spacing: 0.18px;
      line-height: 24px;
      color: #ffffff;
    }
    &:active {
      background: #014694;
    }
  }
  .changepassword-header {
    padding-bottom: 20px;
    .page-header {
      color: #000000;
      text-align: left;
      font-size: 36px;
      font-weight: var(--bs-body-font-weight-medium);
      line-height: 44px;
      letter-spacing: 0.36px;
    }
  }
  .card {
    background: #ffffff;
    border-radius: 16px;
    padding: 32px;
    border: none;
    .card-top-header {
      text-align: left;
      font-size: calc(var(--bs-body-font-size) + 8px);
      font-weight: var(--bs-body-font-weight-medium);
      line-height: 30px;
      letter-spacing: 0.24px;
      color: #000000;
      margin-bottom: 18px;
    }
    .card-body {
      padding: 0px;
    }
    .form-label {
      text-align: left;
      font-size: calc(var(--bs-body-font-size) - 2px);
      font-weight: var(--bs-body-font-weight-medium);
      letter-spacing: 0.28px;
      color: #000000;
      margin-bottom: 8px;
    }
    .form-select,
    .form-control {
      background-color: #f8f9fa;
      border: 1px solid #7d8998;
      border-radius: 8px;
      text-align: left;
      font-size: calc(var(--bs-body-font-size) - 2px);
      font-weight: normal;
      height: 48px;
      letter-spacing: 0.28px;
      color: #000000;
    }
    .changepassword-form {
      margin-bottom: 24px;
      border-bottom: 1px solid #dee2e6;
      padding-bottom: 32px;
    }

    .table-responsive {
      border-radius: 8px;
      thead {
        background: #5d687726;
        border-radius: 8px;
        tr {
          border-radius: 8px;
          th {
            text-align: left;
            font-size: calc(var(--bs-body-font-size) - 2px);
            border: none;
            font-weight: var(--bs-body-font-weight-medium);
            line-height: 20px;
            letter-spacing: 0.28px;
            color: #000000;
            padding: 10px 8px;
            &:first-child {
              padding-left: 24px;
            }
            &:last-child {
              padding-right: 24px;
            }
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid #dee2e6;
          &:last-child {
            border: none;
          }
          td {
            font-size: var(--bs-body-font-size);
            font-weight: normal;
            letter-spacing: 0.32px;
            line-height: 20px;
            color: #000000;
            border: none;
            opacity: 1;
            padding: 14px 8px;
            &:first-child {
              padding-left: 24px;
            }
            &:last-child {
              padding-right: 24px;
            }
            a {
              text-align: left;
              font-size: var(--bs-body-font-size);
              font-weight: normal;
              line-height: 20px;
              letter-spacing: 0.16px;
              color: #014694;
            }
          }
        }
      }
    }
  }
}
