$breakpoints: (
  xs: 449.98px,
  sm: 575.98px,
  md: 767.98px,
  lg: 991.98px,
  xl: 1199.98px,
  xxl: 1399.98px
);

$heightbreakpoints: (
  md: 749.98px
);

@mixin miniheight {
    @media (min-width: map-get($breakpoints, "xl")) and (max-height: map-get($heightbreakpoints, "md")) {
        @content;
    }
}

@mixin xxl {
    @media (max-width: map-get($breakpoints, "xxl")) {
        @content;
    }
}

@mixin xl {
    @media (max-width: map-get($breakpoints, "xl")) {
        @content;
    }
}

@mixin lg {
    @media (max-width: map-get($breakpoints, "lg")) {
        @content;
    }
}

@mixin md {
    @media (max-width: map-get($breakpoints, "md")) {
        @content;
    }
}

@mixin sm {
    @media (max-width: map-get($breakpoints, "sm")) {
        @content;
    }
}

@mixin xs {
    @media (max-width: map-get($breakpoints, "xs")) {
        @content;
    }
}