.theme-freedom {
  .theme-authentication-screen {
    background-image: url("../../assets/img/aaneelcare-pattern-bg.svg");
  }
}
.theme-healthsun {
  .theme-authentication-screen {
    background-image: url("../../assets/img/theme-bg-02.svg");
  }
}
.theme-optimum {
  .theme-authentication-screen {
    background-image: url("../../assets/img/theme-bg-03.svg");
  }
}

.theme-authentication-screen {
  min-height: 100vh;
  padding: 20px 0;
  position: relative;

  background-position: right 60px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1199.98px) {
    background-position: center center;
    background-size: cover;
  }

  @media (max-width: 575.98px) {
    padding: 40px 0;
  }

  .theme-authentication-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: 1199.98px) {
      justify-content: center;
    }
  }

  .theme-container {
    max-width: 1130px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .theme-authentication-left {
    width: 500px;

    @media (max-width: 1199.98px) {
      display: none;
    }

    p {
      font-size: 40px;
      line-height: 56px;
      position: relative;

      &::after {
        content: "";
        width: 100px;
        height: 6px;
        position: absolute;
        bottom: -20px;
        left: 0;
      }
    }
  }

  .theme-authentication-right {
    width: 450px;
    padding: 48px 56px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: 575.98px) {
      width: 380px;
      padding: 36px 40px;
    }

    @media (max-width: 399.98px) {
      width: 350px;
      padding: 32px 30px;
    }
  }

  .alpha-beta-text {
    position: absolute;
    text-transform: uppercase;
    right: 0;
    top: 20px;
    padding: 4px 10px 4px 16px;
    font-size: calc(var(--bs-body-font-size) - 4px);
    border-radius: 24px 0 0 24px;
    font-weight: 600;
  }

  .theme-logo {
    position: relative;
    align-self: flex-start;
    margin-bottom: 50px;
    @media (max-height: 650px) {
      margin-bottom: 36px;
    }
    .theme-version {
      position: absolute;
      bottom: -10px;
      right: 9px;
      font-size: var(--bs-body-font-size);
    }
  }

  .theme-title-main {
    margin-bottom: 32px;
    @media (max-height: 650px) {
      margin-bottom: 24px;
    }
    .theme-title {
      font-weight: bold;
      font-size: calc(var(--bs-body-font-size) + 14px);
      line-height: 40px;
      margin: 0;
      @media (max-width: 575.98px) {
        font-size: calc(var(--bs-body-font-size) + 12px);
        line-height: 36px;
      }
    }
  }

  .theme-welcome-text {
    .theme-title {
      font-size: calc(var(--bs-body-font-size) + 8px);
      line-height: 36px;
      margin: 0;
      @media (max-width: 575.98px) {
        font-size: calc(var(--bs-body-font-size) + 6px);
        line-height: 32px;
      }
    }
    span {
      font-size: var(--bs-body-font-size);
    }
  }

  .theme-information-message {
    padding: 12px 20px;
    font-size: var(--bs-body-font-size);
    letter-spacing: 0.16px;
    border-radius: 8px;
    margin-top: 32px;
    a {
      font-weight: var(--bs-body-font-weight-medium);
      margin: 0 4px;
      text-decoration: underline;
    }
    p {
      margin-top: 12px;
      font-weight: var(--bs-body-font-weight-medium);
      line-height: 24px;
    }
  }

  .theme-btn {
    margin-top: 58px;
    display: flex;
    flex-direction: column;
    .theme-authentication-forgot-password & {
      margin-top: 30px;
    }
    a.theme-login-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        text-decoration: none;
      }
    }
    @media (max-height: 650px) {
      margin-top: 42px;
    }
    @media (max-width: 575.98px) {
      margin-top: 42px;
    }
    .theme-bordered-btn {
      margin-top: 24px;

      @media (max-height: 650px) {
        margin-top: 16px;
      }
    }
  }

  .theme-links {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    .theme-link-single {
      text-align: center;
      margin-top: 12px;
      a {
        font-size: var(--bs-body-font-size);
        font-weight: 600;
      }
      span {
        font-size: var(--bs-body-font-size);
      }
    }
  }

  .copyright-poweredby {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 48px;

    .theme-copyright {
      font-size: calc(var(--bs-body-font-size) - 3px);
      @media (max-width: 575.98px) {
        font-size: calc(var(--bs-body-font-size) - 5px);
      }
    }
    .theme-powered-by {
      position: relative;
      img {
        width: 140px;
        @media (max-width: 575.98px) {
          width: 90px;
        }
      }
      span {
        position: absolute;
        right: 0;
        top: -12px;
        font-weight: 600;
        font-size: calc(var(--bs-body-font-size) - 5px);
        @media (max-width: 575.98px) {
          font-size: 8px;
          top: -10px;
        }
      }
    }
  }
}
